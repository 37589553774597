import { render, staticRenderFns } from "./PublicationsIndex.vue?vue&type=template&id=7d1f0c0b&scoped=true&"
import script from "./PublicationsIndex.vue?vue&type=script&lang=js&"
export * from "./PublicationsIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d1f0c0b",
  null
  
)

export default component.exports